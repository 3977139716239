import React, { useContext } from 'react';
import * as S from './BumpOfferSelectorV2.styles';
import FunnelContext from '../../context/FunnelContext';
import { Image } from '@builder.io/react';

const BumpOfferSelector = props => {
  const {
    bumpOfferIndex: defaultBumpOfferIndex = 0,
    hideBumpOfferDiscount,
    bumpOfferPrefixText,
    hideRibbon,
    children,
    indexSettings
  } = props;

  const context = useContext(FunnelContext);

  const {
    currency: { symbol: currencySymbol },
    onetime,
    subscription,
    currentCategory,
    pageDesign,
    bumpOfferChecked,
    setBumpOfferChecked,
    bumpOfferIndex,
    setBumpOfferIndex
  } = context;

  const bumpoffers =
    currentCategory === `onetime`
      ? onetime?.bumpoffers
      : subscription?.bumpoffers;

  const handleBumpOffer = event => {
    setBumpOfferChecked(event.target.checked);
    setBumpOfferIndex(bumpOfferIndex || defaultBumpOfferIndex || 0);
  };

  const BumpOfferDiscount = ({ discount_percentage }) => {
    return hideBumpOfferDiscount ? (
      ``
    ) : (
      <span className="default">({discount_percentage}% Off)</span>
    );
  };

  const { checkoutData, discount_percentage, display_title } = bumpoffers[
    bumpOfferIndex || defaultBumpOfferIndex || 0
  ];

  let _prefixText =
    bumpOfferPrefixText && bumpOfferPrefixText !== ``
      ? bumpOfferPrefixText
      : `Enhance Your Chews With Our`;
  let _useTemplateTags = false;
  let _title = '';

  if (indexSettings && indexSettings[bumpOfferIndex]) {
    const { title, prefixText, useTemplateTags } = indexSettings[
      bumpOfferIndex
    ];

    _prefixText = prefixText;
    _title = title || '';

    _useTemplateTags = useTemplateTags;
    if (_useTemplateTags) {
      _prefixText = _prefixText?.replace('[title]', display_title);
      _prefixText = _prefixText?.replace(
        '[discounted-price]',
        `${currencySymbol}${checkoutData.discounted_price.toFixed(2)}`
      );
      _prefixText = _prefixText?.replace(
        '[price]',
        `${currencySymbol}${checkoutData.price.toFixed(2)}`
      );
      _prefixText = _prefixText?.replace(
        '[discount-rate]',
        discount_percentage
      );

      _title = _title?.replace('[title]', display_title);
      _title = _title?.replace(
        '[discounted-price]',
        `${currencySymbol}${checkoutData.discounted_price.toFixed(2)}`
      );
      _title = _title?.replace(
        '[price]',
        `${currencySymbol}${checkoutData.price.toFixed(2)}`
      );
      _title = _title?.replace('[discount-rate]', discount_percentage);
    }
  }

  const ContentText = () => {
    return (
      <>
        {_title !== '' && <span dangerouslySetInnerHTML={{ __html: _title }} />}
        <span dangerouslySetInnerHTML={{ __html: _prefixText }} />
        {!_useTemplateTags && (
          <>
            {display_title} for {currencySymbol}
            {checkoutData.discounted_price.toFixed(2)}{' '}
            <BumpOfferDiscount discount_percentage={discount_percentage} />
          </>
        )}
      </>
    );
  };

  return (
    <S.BumpOfferWrapper pageDesign={pageDesign}>
      <S.BumpOffer
        hasChildren={children}
        hideRibbon={hideRibbon}
        className={bumpOfferChecked ? 'active' : ''}
        pageDesign={pageDesign}
      >
        <S.BumpOfferLabel pageDesign={pageDesign} className="label">
          <S.BumpOfferCheckbox
            type="checkbox"
            value={bumpOfferIndex}
            className="checkbox"
            checked={bumpOfferChecked}
            onChange={handleBumpOffer}
          />
          <S.BumpOfferContent pageDesign={pageDesign} className="content">
            {children && children}
            {!children && (
              <>
                <S.BumpOfferImage pageDesign={pageDesign}>
                  <Image alt="bumpoffer" image={checkoutData.image} lazy />
                </S.BumpOfferImage>
                <S.BumpOfferContentText
                  pageDesign={pageDesign}
                  hideRibbon={hideRibbon}
                >
                  <ContentText />
                </S.BumpOfferContentText>
              </>
            )}
          </S.BumpOfferContent>
        </S.BumpOfferLabel>
      </S.BumpOffer>
    </S.BumpOfferWrapper>
  );
};

export default BumpOfferSelector;
